<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerText="Setup Your Billing Account"
            @continue="continueForm"
            @reset="resetForm"
            ref="tabbedForm"
            @finish="finishForm"
            :details="details"
            :dirty="dirty"
            :fetched="fetched"
            :update="finishForm"
        >
            <template v-slot:step-1-form="{step}">
                <b-form-group label="Billing account name" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <b-form-input
                                v-model="$v.accountForm.name.$model"
                                type="text"
                                placeholder="Enter your account name"
                                :id="`${formId}-name`"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('accountForm.name', step)"
                                invalid="This field is required"
                                valid="Name is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Customer name" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <b-form-input
                                v-model="$v.customerDetails.name.$model"
                                type="text"
                                placeholder="Enter your legal/ your company's name"
                                :id="`${formId}-customer-name`"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('customerDetails.name', step)"
                                invalid="This field is required"
                                valid="Name is valid"
                            ></Feedback>
                        </b-col>
                        <b-col>
                            <span v-b-tooltip.hover title="This name will appear on the invoice">
                                <i class="fa fa-question-circle" />
                            </span>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Phone number" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <vue-tel-input
                                v-model="$v.customerDetails.phone.$model"
                                @validate="onPhoneInput"
                                placeholder="Your phone number"
                                class="form-control"
                                :id="`${formId}-customer-phone`"
                            ></vue-tel-input>
                            <Feedback
                                :state="validateRef('customerDetails.phone', step)"
                                invalid="This field is required"
                                valid="Phone number is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Email" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <b-form-input
                                v-model="$v.customerDetails.email.$model"
                                type="email"
                                placeholder="Enter your email address"
                                :id="`${formId}-customer-email`"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('customerDetails.email', step)"
                                invalid="This field is required"
                                valid="Email is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Address" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <b-form-input
                                v-model="$v.customerDetails.address.line1.$model"
                                type="text"
                                placeholder="Address line 1"
                                :id="`${formId}-address-line1`"
                            ></b-form-input>
                        </b-col>
                        <b-col md="8">
                            <b-form-input
                                v-model="$v.customerDetails.address.line2.$model"
                                type="text"
                                placeholder="Address line 2"
                                :id="`${formId}-address-line2`"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('customerDetails.address.line1', step)"
                                invalid="This field is required"
                                valid="Address is valid"
                            ></Feedback>
                        </b-col>
                        <b-col md="4" />
                        <b-col md="3">
                            <b-form-input
                                v-model="$v.customerDetails.address.postalCode.$model"
                                type="text"
                                placeholder="Postal code"
                                :id="`${formId}-address-postal-code`"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('customerDetails.address.postalCode', step)"
                                invalid="This field is required"
                                valid="Code is valid"
                            ></Feedback>
                        </b-col>
                        <b-col md="5">
                            <b-form-input
                                v-model="$v.customerDetails.address.city.$model"
                                type="text"
                                placeholder="City"
                                :id="`${formId}-address-city`"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('customerDetails.address.city', step)"
                                invalid="This field is required"
                                valid="City is valid"
                            ></Feedback>
                        </b-col>
                        <b-col md="8">
                            <wit-select
                                :options="countries"
                                v-model="$v.customerDetails.address.country.$model"
                                placeholder="Country"
                                :id="`${formId}-country`"
                                :allow-empty="false"
                                label="countryName"
                            ></wit-select>
                            <Feedback
                                :state="validateRef('customerDetails.address.country', step)"
                                invalid="This field is required"
                                valid="Country is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Tax ID" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <b-form-input
                                v-model="$v.customerDetails.taxId.$model"
                                type="text"
                                :placeholder="countryTaxId ? `e.g. ${countryTaxId.example}` : 'Tax ID'"
                                :id="`${formId}-tax-id`"
                                :disabled="details && lockTaxId"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('customerDetails.taxId', step)"
                                invalid="This field is required"
                                valid="ID is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>
            </template>
            <template v-slot:step-2-form="step">
                <b-form-group label="Registered cards" :label-cols="2" :horizontal="true" v-if="registeredCards.length">
                    <b-row v-for="card in registeredCards" :key="card.id" :class="`${formId}-registered-card`">
                        <b-col md="8">
                            <b-form-text>Card number</b-form-text>
                            <b-form-input
                                v-model="card.number"
                                type="text"
                                :class="`${formId}-registered-card-number`"
                                readonly
                            ></b-form-input>
                        </b-col>
                        <b-col md="4">
                            <b-button
                                @click="() => removeCardModal(card)"
                                variant="danger"
                                size="sm"
                                :class="`${formId}-remove-registered-card`"
                            >
                                <i class="icon-trash"></i>
                            </b-button>
                        </b-col>
                        <b-col md="4">
                            <b-form-text>Expiry date</b-form-text>
                            <b-form-input
                                v-model="card.expiry"
                                type="text"
                                :class="`${formId}-registered-card-expiry`"
                                readonly
                            ></b-form-input>
                        </b-col>
                        <b-col md="4">
                            <b-form-text>Registered at</b-form-text>
                            <b-form-input
                                v-model="card.registeredAt"
                                type="text"
                                :class="`${formId}-registered-card-date`"
                                readonly
                            ></b-form-input>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group
                    label="New credit or debit card"
                    :label-cols="2"
                    :horizontal="true"
                    v-show="paymentDetails.method === 'CARD'"
                >
                    <b-row>
                        <b-col md="8">
                            <div id="card-number-element"></div>
                            <Feedback
                                :state="validateRef('paymentDetails.cardNumber', step)"
                                :invalid="cardNumberError"
                            ></Feedback>
                        </b-col>
                        <b-col md="4" />
                        <b-col md="4">
                            <div id="card-expiry-element"></div>
                            <Feedback
                                :state="validateRef('paymentDetails.cardExpiry', step)"
                                :invalid="cardExpiryError"
                            ></Feedback>
                        </b-col>
                        <b-col md="4">
                            <div id="card-cvc-element"></div>
                            <Feedback
                                :state="validateRef('paymentDetails.cardCvc', step)"
                                :invalid="cardCvcError"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <div>
                    <b-row v-if="paymentDetails.method === 'CARD' && !registeredCards.length">
                        <b-col md="2" />
                        <b-col>
                            <b-row>
                                <b-col md="8">
                                    <span class="small-info-text">
                                        If your organization does not accept automatic payments, please contact us for
                                        more info
                                    </span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>

                <b-row>
                    <b-col>
                        <Feedback v-if="formError" :state="formError.state" :invalid="formError.message"></Feedback>
                    </b-col>
                </b-row>

                <WitModal
                    v-model="showUpdateModal"
                    title="Confirm your choice"
                    variant="warning"
                    customClass="setup-card-modal"
                    size="md"
                >
                    <div class="setup-card-modal__text">
                        <strong>Now you will be charged:</strong>
                        <p>~{{ getRemainingCost() }}$ for the number of days remaining until the end of the month</p>
                        <strong>Next payments:</strong>
                        <p>125$ on the 1st day of each month</p>
                        <strong>Other costs:</strong>
                        <p>
                            The cost for the consumption of our resources will be charged according to the pricing
                            placed on our
                            <a href="https://witbee.com/pricing" target="_blank">website</a> on the last day of each
                            month
                        </p>
                        <hr />
                        <p>By setting up this info your are activating your subscription. Are you sure?</p>
                    </div>
                    <b-row class="d-block text-center">
                        <b-button
                            variant="success"
                            size="md"
                            @click="
                                () => {
                                    showUpdateModal = false
                                    details ? updateAccount(step.step) : createAccount(step.step)
                                }
                            "
                            >Yes</b-button
                        >
                        <span class="pad-buttons" />
                        <b-button variant="danger" size="md" @click="() => (showUpdateModal = false)">No</b-button>
                    </b-row>
                </WitModal>

                <WitModal
                    v-model="showDeactivateModal"
                    title="Action required"
                    variant="warning"
                    customClass="setup-card-modal"
                    size="md"
                >
                    <div class="setup-card-modal__text">
                        If you wish to remove your billing info, you need to deactivate your billing account first.
                    </div>
                    <b-row class="d-block text-center">
                        <project-router-link :to="`/billing/accounts/deactivate/${$route.params.id}`">
                            <b-button variant="danger" size="md">Deactivate</b-button>
                        </project-router-link>
                        <span class="pad-buttons" />
                        <b-button variant="warning" size="md" @click="() => (showDeactivateModal = false)"
                            >Back</b-button
                        >
                    </b-row>
                </WitModal>
            </template>
        </wit-tabbed-form>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
        <WitModal v-model="showModal" title="Are you sure?" variant="warning" customClass="remove-card-modal" size="md">
            <div class="remove-card-modal__text">Are you sure you want to delete this card?</div>
            <b-row class="d-block text-center">
                <b-button variant="success" size="md" @click="removeCard">Yes</b-button>
                <span class="pad-buttons" />
                <b-button variant="danger" size="md" @click="() => (showModal = false)">No</b-button>
            </b-row>
        </WitModal>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {email, minLength, required, requiredIf} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'
import {VueTelInput} from 'vue-tel-input'
import {loadStripe} from '@stripe/stripe-js'
import moment from 'moment'

import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import WitModal from '@/components/Modals/WitModal'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {formMixin} from '@/mixins/formMixin'

import {filterDirty} from '@/shared/filterDirty.js'
import {taxIds} from '@/shared/taxIds.js'

const cloneDeep = require('lodash.clonedeep')
const countries = require('country-region-data')
const d3 = require('d3-time')

export default {
    data() {
        return {
            formId: 'billing-account-create-form',
            cannotContinue: true,
            accountForm: {
                name: '',
            },
            customerDetails: {
                name: '',
                phone: '',
                email: '',
                address: {
                    line1: '',
                    line2: '',
                    postalCode: '',
                    city: '',
                    country: '',
                },
                taxId: '',
            },
            paymentDetails: {
                method: 'CARD',
                cardNumber: null,
                cardExpiry: null,
                cardCvc: null,
            },
            account: null,
            phoneIsValid: false,
            formError: {
                state: true,
                message: null,
            },
            cardNumberIsValid: false,
            cardExpiryIsValid: false,
            cardCvcIsValid: false,
            cardNumberError: '',
            cardExpiryError: '',
            cardCvcError: '',
            cardNumberEmpty: true,
            cardExpiryEmpty: true,
            cardCvcEmpty: true,
            details: false,
            registeredCards: [],
            showModal: false,
            showUpdateModal: false,
            showDeactivateModal: false,
            lockTaxId: false,
        }
    },
    components: {
        Feedback,
        Loading,
        WitModal,
        WitTabbedForm,
        VueTelInput,
    },
    mixins: [formMixin, validationMixin],
    validations: {
        accountForm: {
            name: {required},
        },
        customerDetails: {
            name: {required},
            phone: {
                valid: function () {
                    return Boolean(this.phoneIsValid)
                },
            },
            email: {required, email},
            address: {
                line1: {required},
                line2: {},
                postalCode: {required},
                city: {required},
                country: {required},
            },
            taxId: {
                required,
                valid: (value) => /^(?![\W_]+$)(?=.{2,12}$)[-_ 0-9]*(?:[a-zA-Z][-_ 0-9]*){0,2}$/.test(value),
            },
        },
        paymentDetails: {
            method: {required},
            cardNumber: {
                valid: function () {
                    return this.paymentDetails.method === 'CARD' && !this.registeredCards.length && !this.details
                        ? !this.cardNumberEmpty && this.cardNumberIsValid
                        : true
                },
            },
            cardExpiry: {
                valid: function () {
                    return this.paymentDetails.method === 'CARD' && !this.registeredCards.length && !this.details
                        ? !this.cardExpiryEmpty && this.cardExpiryIsValid
                        : true
                },
            },
            cardCvc: {
                valid: function () {
                    return this.paymentDetails.method === 'CARD' && !this.registeredCards.length && !this.details
                        ? !this.cardCvcEmpty && this.cardCvcIsValid
                        : true
                },
            },
        },
    },
    computed: {
        steps() {
            const steps = [
                {
                    name: 'Customer Details',
                    invalid: this.$v.accountForm.$invalid || this.$v.customerDetails.$invalid,
                },
            ]

            if (this.paymentDetails.method === 'CARD') {
                steps.push({
                    name: 'Payment Details',
                    invalid: this.$v.$invalid,
                })
            }

            return steps
        },

        cardEmpty() {
            return this.cardNumberEmpty && this.cardExpiryEmpty && this.cardCvcEmpty
        },

        countries() {
            return Object.keys(taxIds)
                .sort()
                .map((key) => countries.find((country) => country.countryName === key))
                .filter(Boolean)
        },

        countryTaxId() {
            return this.customerDetails.address.country
                ? taxIds[this.customerDetails.address.country.countryName]
                : null
        },
    },
    async created() {
        if (this.$route.params.id) {
            this.$store.commit('loading/PROCESSING', `Fetching your billing account...`)
            this.details = true
            await this.fetchAccount()
            this.fetched = true
            this.$store.commit('loading/PROCESSED')
        } else {
            this.mountStripe()
        }
        if (this.$route.query.configure && !this.$v.accountForm.$invalid && !this.$v.customerDetails.$invalid) {
            this.$refs.tabbedForm.tabIndex = 1
        }
        this.$v.customerDetails.phone.$reset()
    },
    methods: {
        async mountStripe() {
            this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY)
            const elements = this.stripe.elements()

            this.cardNumber = elements.create('cardNumber')
            this.cardExpiry = elements.create('cardExpiry')
            this.cardCvc = elements.create('cardCvc')

            this.cardNumber.mount('#card-number-element')
            this.cardExpiry.mount('#card-expiry-element')
            this.cardCvc.mount('#card-cvc-element')

            this.cardNumber.on('change', (event) => {
                this.cardNumberEmpty = event.empty
                this.$v.paymentDetails.cardNumber.$model = event

                if (event.error) {
                    this.cardNumberError = event.error.message
                    this.cardNumberIsValid = false
                } else {
                    this.cardNumberError = ''
                    this.cardNumberIsValid = true
                }
            })

            this.cardExpiry.on('change', (event) => {
                this.cardExpiryEmpty = event.empty
                this.$v.paymentDetails.cardExpiry.$model = event

                if (event.error) {
                    this.cardExpiryError = event.error.message
                    this.cardExpiryIsValid = false
                } else {
                    this.cardExpiryError = ''
                    this.cardExpiryIsValid = true
                }
            })

            this.cardCvc.on('change', (event) => {
                this.cardCvcEmpty = event.empty
                this.$v.paymentDetails.cardCvc.$model = event

                if (event.error) {
                    this.cardCvcError = event.error.message
                    this.cardCvcIsValid = false
                } else {
                    this.cardCvcError = ''
                    this.cardCvcIsValid = true
                }
            })
        },

        onPhoneInput({isValid}) {
            if (this.$v.customerDetails.phone.$dirty) {
                this.$v.customerDetails.phone.$model = this.customerDetails.phone
            }
            this.phoneIsValid = isValid
        },

        getRemainingCost() {
            const now = moment()

            const startOfMonth = moment().startOf('month')
            const endOfMonth = moment().endOf('month')
            const hoursInMonth = endOfMonth.diff(startOfMonth, 'hours')

            const costPerHour = (125 / hoursInMonth).toFixed(2)

            const hoursTillEndOfMonth = endOfMonth.diff(now, 'hours')

            return (hoursTillEndOfMonth * costPerHour).toFixed(2)
        },

        async fetchAccount() {
            const {
                data: {data: account},
            } = await this.axios.get(`${process.env.VUE_APP_NODE_API_HOST}/billingAccount/${this.$route.params.id}`)
            const {
                data: {data: customer},
            } = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/billingAccount/${this.$route.params.id}/details`
            )
            const {
                data: {data: cards},
            } = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/billingAccount/${this.$route.params.id}/paymentMethods`
            )

            this.paymentDetails.method = account.budget ? 'INVOICE' : 'CARD'

            let country = customer.address
                ? this.countries.find((ct) => ct.countryShortCode === customer.address.country)
                : null

            country =
                !country && customer.address
                    ? this.countries.find((ct) => ct.countryName === customer.address.country)
                    : country

            this.account = account
            this.accountForm = {
                name: account.name,
            }
            this.customerDetails = {
                name: customer.name,
                phone: customer.phone || '',
                email: customer.email,
                address: {
                    line1: customer.address ? customer.address.line1 : '',
                    line2: customer.address ? customer.address.line2 : '',
                    city: customer.address ? customer.address.city : '',
                    postalCode: customer.address ? customer.address.postal_code : '',
                    country,
                },
                taxId: '',
            }

            this.customerDetails.taxId =
                customer.taxIds && customer.taxIds.length
                    ? customer.taxIds[0].value.substring(this.countryTaxId.codePrefix.length)
                    : ''

            if (customer.taxIds && customer.taxIds.length) {
                this.lockTaxId = true
            }

            this.currentForm = {
                accountForm: cloneDeep(this.accountForm),
                customerDetails: cloneDeep(this.customerDetails),
                paymentMethod: account.paymentMethod,
            }

            this.registeredCards = cards.map((cardInfo) => ({
                number:
                    cardInfo.card.brand === 'amex'
                        ? `**** ***** *${cardInfo.card.last4}`
                        : `**** **** **** ${cardInfo.card.last4}`,
                expiry: `${
                    String(cardInfo.card.exp_month).length === 1
                        ? `0${cardInfo.card.exp_month}`
                        : cardInfo.card.exp_month
                }/${String(cardInfo.card.exp_year).substring(2)}`,
                registeredAt: new Date(cardInfo.created * 1000).toLocaleString(),
                id: cardInfo.id,
            }))

            if (!account.budget) {
                this.mountStripe()
            }
        },

        finishForm() {
            this.$forceUpdate()

            this.formError = {
                state: true,
                message: '',
            }

            if (!this.$v.$invalid) {
                if (this.details) {
                    if (!this.account.active && this.paymentDetails.method === 'CARD' && !this.registeredCards.length) {
                        this.showUpdateModal = true
                    } else {
                        this.updateAccount()
                    }
                } else {
                    this.showUpdateModal = true
                }
            }
        },

        async createAccount(step) {
            step.check()
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                try {
                    this.$store.commit('loading/PROCESSING', `Creating your billing account...`)
                    const account = await this.createDetails()

                    if (this.paymentDetails.method === 'CARD') {
                        this.$store.commit('loading/PROCESSING', `Updating your payment details...`)
                        await this.setupCard(account.id)
                    }

                    this.$store.commit('loading/PROCESSING', `Activating...`)
                    await this.activate(account.id)

                    this.$gtm.trackEvent({event: 'payment_activation'})

                    await this.$store.dispatch('billing/getAccounts')
                    this.$store.commit('loading/PROCESSED')
                    this.$projectRouter.push(`/billing/accounts?accountCreated=true`)
                } catch (e) {
                    this.$store.commit('loading/PROCESSED')
                    this.$errorHandler.report(e, 'Could not create billing account')
                }
            }
        },

        async createDetails() {
            const {
                taxId,
                address: {country, ...address},
                ...customerData
            } = this.customerDetails

            const data = {
                ...this.accountForm,
                customerData: {
                    ...customerData,
                    address: {
                        ...address,
                        country: country.countryShortCode,
                    },
                    taxIdData: [
                        {
                            type: this.countryTaxId.type,
                            value: this.countryTaxId.codePrefix + taxId,
                        },
                    ],
                },
            }

            try {
                const {
                    data: {data: billingAccount},
                } = await this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/billingAccount`, data)

                return billingAccount
            } catch (exception) {
                this.formError.state = false

                if (
                    exception.response &&
                    exception.response.data.data &&
                    exception.response.data.data.includes(this.countryTaxId.type)
                ) {
                    this.formError.message = `${exception.response.data.data} Example value: ${this.countryTaxId.example}`
                } else {
                    this.formError.message = exception.response.data.data
                }

                this.$store.commit('loading/PROCESSED')
                throw exception
            }
        },

        async updateAccount(step) {
            if (step) {
                step.check()
            }
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                const newForm = {
                    accountForm: this.accountForm,
                    customerDetails: this.customerDetails,
                    paymentMethod: this.paymentDetails.method,
                }
                const formDirty = filterDirty(newForm, this.currentForm)
                let query = {}
                let paymentUpdated = false

                try {
                    if (Object.keys(formDirty).length > 0) {
                        this.$store.commit('loading/PROCESSING', `Updating your billing account...`)
                        await this.updateDetails()
                        query.accountUpdated = true
                    }

                    if (this.paymentDetails.method === 'CARD' && !this.cardEmpty) {
                        this.$store.commit('loading/PROCESSING', `Updating your payment details...`)
                        await this.setupCard(this.$route.params.id)
                        query.accountUpdated = true
                        paymentUpdated = true
                    }

                    if (!this.account.active && !this.registeredCards.length && paymentUpdated) {
                        this.$store.commit('loading/PROCESSING', `Activating...`)
                        await this.activate(this.$route.params.id)
                        query.accountUpdated = true
                    }

                    if (this.$route.query.configure) {
                        query.configured = true
                    }

                    await this.$store.dispatch('billing/getAccounts')
                    this.$store.commit('loading/PROCESSED')
                    return this.$projectRouter.push({path: `/billing/accounts`, query})
                } catch (err) {
                    this.$errorHandler.report(err, 'Could not update billing account')
                    console.error(err)
                    return
                }
            }
        },

        async updateDetails() {
            const {
                taxId,
                address: {country, ...address},
                ...customerData
            } = this.customerDetails

            const data = {
                ...this.accountForm,
                customerData: {
                    ...customerData,
                    address: {
                        ...address,
                        country: country.countryShortCode,
                    },
                    taxIdData: this.lockTaxId
                        ? undefined
                        : [
                              {
                                  type: this.countryTaxId.type,
                                  value: this.countryTaxId.codePrefix + taxId,
                              },
                          ],
                },
            }

            try {
                const response = await this.axios.patch(
                    `${process.env.VUE_APP_NODE_API_HOST}/billingAccount/${this.$route.params.id}`,
                    data
                )
                return response
            } catch (exception) {
                this.formError.state = false
                this.formError.message = exception.response.data.data
                this.$store.commit('loading/PROCESSED')
                throw exception
            }
        },

        async setupCard(billingAccountId) {
            const {
                data: {data: intent},
            } = await this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/billingAccount/setupIntent`, {
                billingAccountId,
            })

            const clientSecret = intent.client_secret
            const {setupIntent, error} = await this.stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: this.cardNumber,
                },
            })

            if (error) {
                this.formError.state = false
                this.formError.message = error.message
                this.$store.commit('loading/PROCESSED')
                throw error
            } else {
                return setupIntent
            }
        },

        async activate(billingAccountId) {
            try {
                const response = await this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/billingAccount/activate`, {
                    billingAccountId,
                    paymentMethod: 'CARD',
                })

                return response
            } catch (exception) {
                this.formError.state = false
                this.formError.message = exception.response.data.data
                this.$store.commit('loading/PROCESSED')
                throw exception
            }
        },

        removeCardModal(card) {
            if (this.registeredCards.length === 1 && this.account.active) {
                this.showDeactivateModal = true
            } else {
                this.removeCardId = card.id
                this.showModal = true
            }
        },

        async removeCard() {
            this.$store.commit('loading/PROCESSING', `Removing your card...`)

            const result = await this.axios.post(
                `${process.env.VUE_APP_NODE_API_HOST}/billingAccount/removePaymentMethod`,
                {
                    billingAccountId: this.$route.params.id,
                    paymentMethodId: this.removeCardId,
                }
            )

            await this.fetchAccount()

            this.removeCardId = null
            this.showModal = false
            this.$store.commit('loading/PROCESSED')
        },
    },
}
</script>

<style lang="scss">
.form-control.vue-tel-input {
    border-radius: 5px;
    border: 1px solid #e8e8e8;
}

#billing-account-create-form-address-line1 {
    margin-bottom: 7px;
}

.billing-account-create-form-registered-card {
    margin-bottom: 15px;
}

.billing-account-create-form-remove-registered-card {
    margin-top: 20px;
}

.StripeElement {
    box-sizing: border-box;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    background-color: white;
    line-height: 1.5;
    cursor: text;
}

.StripeElement--focus {
    border-color: #8ad4ee;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

#billing-account-create-form-method {
    margin-top: 7px;
}

.pad-buttons {
    padding-left: 2rem;
}

.remove-card-modal {
    header {
        h5 {
            font-size: 0.9rem;
        }
    }

    .modal-body {
        .remove-card-modal__text {
            margin-bottom: 15px;
        }
    }
}

.setup-card-modal {
    header {
        h5 {
            font-size: 0.9rem;
        }
    }

    .modal-body {
        .setup-card-modal__text {
            margin-bottom: 15px;
        }
    }
}
</style>
